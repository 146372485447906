var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container parkman-page" },
    [
      _c(
        "div",
        { staticClass: "df jc-sb ai-c" },
        [
          _c("iq-title", { attrs: { "class-name": "mobileHeader" } }, [
            _vm._v("Паркмен:"),
          ]),
          _c(
            "iq-button",
            {
              staticClass: "m-l-10",
              attrs: {
                icon: "IconSettings",
                "icon-height": "14px",
                "icon-width": "14px",
              },
              on: { onClick: _vm.handleTableColumns },
            },
            [_vm._v(" Настроить таблицу ")]
          ),
        ],
        1
      ),
      _vm._m(0),
      _c(
        "LoaderWrapper",
        {
          attrs: {
            loading: _vm.getIsLoadingCallDriver,
            text: "Идет звонок водителю, пожалуйста, подождите!",
          },
        },
        [
          _c(
            "div",
            { staticClass: "parkman-page__table-wrap" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.getIsLoading || _vm.isDetaching,
                      expression: "getIsLoading || isDetaching",
                    },
                  ],
                  key: _vm.updateTable,
                  staticClass: "parkman-page__table",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData, stripe: "" },
                },
                [
                  !_vm.isMobile
                    ? _c("el-table-column", {
                        attrs: { align: "left", width: "180" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "iq-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        color: "primary",
                                        disabled:
                                          scope.row.truck_status === "detached",
                                      },
                                      on: {
                                        onClick: function ($event) {
                                          return _vm.startDetach(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v(" Вернуть на парковку ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3038224981
                        ),
                      })
                    : _vm._e(),
                  _vm._l(_vm.controlTableFields, function (item) {
                    return _c(
                      "el-table-column",
                      {
                        key: item.prop,
                        attrs: {
                          "header-align": "center",
                          align: "center",
                          width: item.width,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("ParkmanTableCell", {
                                    attrs: {
                                      row: scope.row,
                                      value: scope.row[item.prop],
                                      "prop-key": item.prop,
                                      command: item.command,
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "template",
                          { slot: "header" },
                          [_c("TableLabel", { attrs: { data: item } })],
                          1
                        ),
                      ],
                      2
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "class-name": "parkman-page__mobile-btns",
                      align: "center",
                      width: "220",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.isMobile
                              ? _c("iq-button", {
                                  attrs: {
                                    size: "mini",
                                    color: "primary",
                                    "icon-width": "18px",
                                    "icon-height": "18px",
                                    icon: "IconClose",
                                    disabled:
                                      scope.row.truck_status === "detached",
                                  },
                                  on: {
                                    onClick: function ($event) {
                                      return _vm.setDetachStatus(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _c(
                              "iq-button",
                              {
                                attrs: {
                                  icon: "IconGoToTerminal",
                                  size: "mini",
                                  "icon-width": "18px",
                                  "icon-height": "18px",
                                  disabled:
                                    scope.row.truck_status === "detached",
                                },
                                on: {
                                  onClick: function ($event) {
                                    return _vm.toTerminal(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" проезд к терминалу ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
      _c("DialogChangeTableColumn"),
      _c("DialogDetachTruck", { on: { detach: _vm.setDetachStatus } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "parkmanWarning" }, [
      _c("div", { staticClass: "parkmanWarning__red highlight" }, [
        _vm._v(" Кнопку “Вернуть на парковку” следует нажимать когда: "),
      ]),
      _c("ul", { staticClass: "parkmanWarning__default" }, [
        _c("li", [
          _vm._v(
            " у водителя были обнаружены несоответствия в документах, качестве зерна "
          ),
        ]),
        _c("li", [_vm._v("ему запрещён проезд на територию терминала")]),
        _c("li", [
          _vm._v(
            " авто было вызвано в буфер, но фактически не подъезжает в течение длительного времени "
          ),
        ]),
      ]),
      _c("div", { staticClass: "parkmanWarning__red highlight" }, [
        _vm._v(
          " Это необходимо, чтобы система могла вызвать в буфер следующую машину. "
        ),
      ]),
      _c("div", { staticClass: "parkmanWarning__red" }, [
        _vm._v(
          " После возвращения на парковку водитель решает возникшие проблемы, а затем обращается в службу поддержки и сможет проехать на выгрузку. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }